import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './Social.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

/**
 * A single social link
 *
 * @param icon Icon representing the social site
 * @param link Target link when pressing the icon
 *
 * @constructor
 */
export const SocialLink = ({
  icon,
  link
}: {
  icon: IconProp;
  link: string;
}) => (
  <a href={link}>
    <FontAwesomeIcon icon={icon} className={styles.icon} />
  </a>
);
