import React from 'react';

import styles from './Header.module.scss';
import photo from '../photo.png';

/**
 * Page header
 *
 * @param name Name of the person
 * @param about Brief biographical blurb
 *
 * @constructor
 */
export const Header = ({ name, about }: { name: string; about: string }) => {
  return (
    <div className={styles.header}>
      <img src={photo} className={styles.photo} alt='Sean Coyle' />

      <div className={styles.bio}>
        <h1 className={styles.name}>{name}</h1>
        <div className={styles.about}>{about}</div>
      </div>
    </div>
  );
};
