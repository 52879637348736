import React from 'react';

import styles from './Achievement.module.scss';

/**
 * Lists a single achievement at a job/school
 *
 * @param text Text content of the achievement
 *
 * @constructor
 */
export const Achievement = ({ text }: { text: string }) => {
  return <li className={styles.achievement}>{text}</li>;
};
