import React from 'react';

import { Header } from '../components/Header';
import { Section } from '../components/Section';
import { Social } from '../components/Social';

import styles from './App.module.scss';

import data from '../data.json';
import '../library';
import { Data } from '../types';

const typedData = data as Data;

export const App = () => (
  <div className={styles.App}>
    <Header about={typedData.about} name={typedData.name} />

    {typedData.sections.map((section) => (
      <Section {...section} key={section.title} />
    ))}

    <Social links={typedData.social} />
  </div>
);
