import React from 'react';

import { SocialLink } from './SocialLink';

import styles from './Social.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

/**
 * A group of social links
 *
 * @param links List of links
 *
 * @constructor
 */
export const Social = ({
  links
}: {
  links: Array<{ link: string; icon: IconProp }>;
}) => {
  return (
    <div className={styles.social}>
      {links.map(({ link, icon }) => (
        <SocialLink icon={icon} link={link} key={link} />
      ))}
    </div>
  );
};
