import React from 'react';

import { Achievement } from './Achievement';

import styles from './Experience.module.scss';

/**
 * A single job or education experience
 *
 * @param achievements Achievements at the job/school
 * @param place Location of the experience
 * @param range Date range
 * @param role Role at job/school
 * @param site Website for the job/school
 *
 * @constructor
 */
export const Experience = ({
  achievements,
  place,
  range,
  role,
  site
}: {
  achievements?: string[];
  place: string;
  range: string;
  role: string;
  site: string;
}) => {
  return (
    <div className={styles.entry}>
      <div className={styles.role}>{role}</div>
      <div className={styles.range}>{range}</div>
      <div className={styles.place}>
        <a href={site}>{place}</a>
      </div>

      {achievements?.length ? (
        <ul className={styles.achievements}>
          {achievements.map((achievement) => (
            <Achievement text={achievement} key={achievement} />
          ))}
        </ul>
      ) : null}
    </div>
  );
};
