import React from 'react';

import { SectionHeader } from './SectionHeader';
import { Experience } from './Experience';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Experience as ExperienceType } from '../types';

/**
 * Section, i.e. work/education
 *
 * @param title Name of the section
 * @param experiences Experiences within the section
 * @param icon Shown alongside the name
 *
 * @constructor
 */
export const Section = ({
  title,
  experiences,
  icon
}: {
  title: string;
  experiences: ExperienceType[];
  icon: IconProp;
}) => {
  return (
    <React.Fragment>
      <SectionHeader content={title} icon={icon} />

      {experiences.map((experience) => (
        <Experience {...experience} key={experience.range} />
      ))}
    </React.Fragment>
  );
};
