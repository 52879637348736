import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

/**
 * Header atop a section
 *
 * @param content Text content of the header
 * @param icon Icon shown next to the header
 *
 * @constructor
 */
export const SectionHeader = ({
  content,
  icon
}: {
  content: string;
  icon: IconProp;
}) => {
  return (
    <h2>
      <FontAwesomeIcon icon={icon} />
      &nbsp;
      {content}
    </h2>
  );
};
